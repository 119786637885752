import React from 'react';
import { useState,useEffect } from 'react';
import { Route, useNavigate, Link, redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import Produit from './produit';
import { useHistory } from 'react-router-dom';
import {browserHistory} from "react-router";

import { useLocation } from 'react-router-dom'
//var avigation = require('react-router-dom').Navigation;

//var navigate = require('react-router-dom').useNavigate();
function Souscategorie(props){

  const location = useLocation();
  const { idcategorie, nomcategorie } = location.state;
    // navigate = Navigate();
     //navigate("/produit");
     const [datacontentSouscategorie,setdatacontentSouscategorie] = useState([]);
     const [selectedFile,setselectedFile] = useState([]);
     const [responseArray,setresponseArray] = useState([]);
     const [nomproduit,setnomproduit] = useState("");
     const [souscategorie,setSouscategorie] = useState("");
     const [prix,setprix] = useState("");
     const [stock,setstock] = useState("");
     const [nomfournisseur,setnomfournisseur] = useState("");
     const [reload,setreload] = useState(true);
  const navigate = useNavigate();
  function handleInputChange(event){

    setselectedFile(event.target.files);
    setresponseArray([]);
    // this.setState({
    //   selectedFile: event.target.files,
    //   responseArray:[],
      

    // });
  
  };
function saveSouscategorie(){
  const data = new FormData();
  //data.append("idSouscategorieparent", nomproduit);
    data.append("nomsouscategorie", souscategorie);
    data.append("idcategorieparent", idcategorie);

    let url = "https://jojomarket.net/adminfood/categorie.php?addsouscategorie1";
   
   
    axios
      .post(url, data, {
        // receive two parameter endpoint url ,form data
      })
      .then((res) => {

setreload(!reload);
        console.log(res.data);
        // then print response status
        //this.setState({ responseArray: res.data });
       // setresponseArray(res.data);
       // resetFile();
        //navigate('/produit');
     //alert('super');
       // this.push = () => {
        //this.props.router.push('/produit');
       //   };

       //this.state.redirect && <Navigate to='/produit' replace={true}/>
    //this.state.history.push("/produit");
    
       //this.window.location('/produit');
      },error=>{
        alert(error);
      });
     
  }

  const suppression = async(idcategorie)=>{
    var urlsup= "https://jojomarket.net/adminfood/categorie.php?supprimer&idcategorie="+idcategorie;
    const responsemodif = await axios.get(urlsup);
   // document.getElementById(idproduit).innerText = stock;
   
   setreload(!reload);
   //this.Tableproduit(props);
  }
 
 
  
 var urllisteSouscategorie = "https://jojomarket.net/adminfood/categorie.php?listesouscategorie1="+idcategorie;

  useEffect(()=>{
    
    loadContent(urllisteSouscategorie);
  }, [idcategorie]);

  useEffect(()=>{
    
    loadContent(urllisteSouscategorie);
  }, [reload]);



  const loadContent = async(url)=>{
    const response = await axios.get(url);

    console.log(response.data);
    setdatacontentSouscategorie(response.data);
  
  }
  const functionmodifier = async(idcategorie,nouvellevaleur)=>{
    var urlencaisse= "https://jojomarket.net/adminfood/categorie.php?modifier&idcategorie="+idcategorie+"&valeur="+nouvellevaleur;
    const responsemodif = await axios.get(urlencaisse);
    //document.getElementById(i).innerText = stock;
    loadContent(urllisteSouscategorie);
  }
  
  return (
    <div>
     <div><div className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 " id="sidenav-main">
    <div className="sidenav-header">
      <i className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none" aria-hidden="true" id="iconSidenav" />
      <a className="navbar-brand m-0" href=" administrateur.jojomarket.net " target="_blank">
        <img src="../assets/img/logo-ct-dark.png" className="navbar-brand-img h-100" alt="main_logo" />
        <span className="ms-1 font-weight-bold">JOJO-MARKET Admin</span>
      </a>
    </div>
    <hr className="horizontal dark mt-0" />
    <div className="collapse navbar-collapse  w-auto " id="sidenav-collapse-main">
    <ul className="navbar-nav">
        <li className="nav-item">
          <a className="nav-link " href="/">
            <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
              <svg width="12px" height="12px" viewBox="0 0 45 40" version="1.1" xmlns="https://www.w3.org/2000/svg" xmlnsXlink="https://www.w3.org/1999/xlink">
                <title>JOJO-MARKET Admin</title>
                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                  <g transform="translate(-1716.000000, -439.000000)" fill="#FFFFFF" fillRule="nonzero">
                    <g transform="translate(1716.000000, 291.000000)">
                      <g transform="translate(0.000000, 148.000000)">
                        <path className="color-background opacity-6" d="M46.7199583,10.7414583 L40.8449583,0.949791667 C40.4909749,0.360605034 39.8540131,0 39.1666667,0 L7.83333333,0 C7.1459869,0 6.50902508,0.360605034 6.15504167,0.949791667 L0.280041667,10.7414583 C0.0969176761,11.0460037 -1.23209662e-05,11.3946378 -1.23209662e-05,11.75 C-0.00758042603,16.0663731 3.48367543,19.5725301 7.80004167,19.5833333 L7.81570833,19.5833333 C9.75003686,19.5882688 11.6168794,18.8726691 13.0522917,17.5760417 C16.0171492,20.2556967 20.5292675,20.2556967 23.494125,17.5760417 C26.4604562,20.2616016 30.9794188,20.2616016 33.94575,17.5760417 C36.2421905,19.6477597 39.5441143,20.1708521 42.3684437,18.9103691 C45.1927731,17.649886 47.0084685,14.8428276 47.0000295,11.75 C47.0000295,11.3946378 46.9030823,11.0460037 46.7199583,10.7414583 Z" />
                        <path className="color-background" d="M39.198,22.4912623 C37.3776246,22.4928106 35.5817531,22.0149171 33.951625,21.0951667 L33.92225,21.1107282 C31.1430221,22.6838032 27.9255001,22.9318916 24.9844167,21.7998837 C24.4750389,21.605469 23.9777983,21.3722567 23.4960833,21.1018359 L23.4745417,21.1129513 C20.6961809,22.6871153 17.4786145,22.9344611 14.5386667,21.7998837 C14.029926,21.6054643 13.533337,21.3722507 13.0522917,21.1018359 C11.4250962,22.0190609 9.63246555,22.4947009 7.81570833,22.4912623 C7.16510551,22.4842162 6.51607673,22.4173045 5.875,22.2911849 L5.875,44.7220845 C5.875,45.9498589 6.7517757,46.9451667 7.83333333,46.9451667 L19.5833333,46.9451667 L19.5833333,33.6066734 L27.4166667,33.6066734 L27.4166667,46.9451667 L39.1666667,46.9451667 C40.2482243,46.9451667 41.125,45.9498589 41.125,44.7220845 L41.125,22.2822926 C40.4887822,22.4116582 39.8442868,22.4815492 39.198,22.4912623 Z" />
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <span className="nav-link-text ms-1">Commande</span>
          </a>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/produit">
            <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
              <svg width="12px" height="12px" viewBox="0 0 42 42" version="1.1" xmlns="https://www.w3.org/2000/svg" xmlnsXlink="https://www.w3.org/1999/xlink">
                <title>Produit</title>
                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                  <g transform="translate(-1869.000000, -293.000000)" fill="#FFFFFF" fillRule="nonzero">
                    <g transform="translate(1716.000000, 291.000000)">
                      <g id="office" transform="translate(153.000000, 2.000000)">
                        <path className="color-background opacity-6" d="M12.25,17.5 L8.75,17.5 L8.75,1.75 C8.75,0.78225 9.53225,0 10.5,0 L31.5,0 C32.46775,0 33.25,0.78225 33.25,1.75 L33.25,12.25 L29.75,12.25 L29.75,3.5 L12.25,3.5 L12.25,17.5 Z" />
                        <path className="color-background" d="M40.25,14 L24.5,14 C23.53225,14 22.75,14.78225 22.75,15.75 L22.75,38.5 L19.25,38.5 L19.25,22.75 C19.25,21.78225 18.46775,21 17.5,21 L1.75,21 C0.78225,21 0,21.78225 0,22.75 L0,40.25 C0,41.21775 0.78225,42 1.75,42 L40.25,42 C41.21775,42 42,41.21775 42,40.25 L42,15.75 C42,14.78225 41.21775,14 40.25,14 Z M12.25,36.75 L7,36.75 L7,33.25 L12.25,33.25 L12.25,36.75 Z M12.25,29.75 L7,29.75 L7,26.25 L12.25,26.25 L12.25,29.75 Z M35,36.75 L29.75,36.75 L29.75,33.25 L35,33.25 L35,36.75 Z M35,29.75 L29.75,29.75 L29.75,26.25 L35,26.25 L35,29.75 Z M35,22.75 L29.75,22.75 L29.75,19.25 L35,19.25 L35,22.75 Z" />
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
           <span className="nav-link-text ms-1">Produit</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link  active" to="/categorie">
            <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
              <svg width="12px" height="12px" viewBox="0 0 42 42" version="1.1" xmlns="https://www.w3.org/2000/svg" xmlnsXlink="https://www.w3.org/1999/xlink">
                <title>Categorie</title>
                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                  <g transform="translate(-1869.000000, -293.000000)" fill="#FFFFFF" fillRule="nonzero">
                    <g transform="translate(1716.000000, 291.000000)">
                      <g id="office" transform="translate(153.000000, 2.000000)">
                        <path className="color-background opacity-6" d="M12.25,17.5 L8.75,17.5 L8.75,1.75 C8.75,0.78225 9.53225,0 10.5,0 L31.5,0 C32.46775,0 33.25,0.78225 33.25,1.75 L33.25,12.25 L29.75,12.25 L29.75,3.5 L12.25,3.5 L12.25,17.5 Z" />
                        <path className="color-background" d="M40.25,14 L24.5,14 C23.53225,14 22.75,14.78225 22.75,15.75 L22.75,38.5 L19.25,38.5 L19.25,22.75 C19.25,21.78225 18.46775,21 17.5,21 L1.75,21 C0.78225,21 0,21.78225 0,22.75 L0,40.25 C0,41.21775 0.78225,42 1.75,42 L40.25,42 C41.21775,42 42,41.21775 42,40.25 L42,15.75 C42,14.78225 41.21775,14 40.25,14 Z M12.25,36.75 L7,36.75 L7,33.25 L12.25,33.25 L12.25,36.75 Z M12.25,29.75 L7,29.75 L7,26.25 L12.25,26.25 L12.25,29.75 Z M35,36.75 L29.75,36.75 L29.75,33.25 L35,33.25 L35,36.75 Z M35,29.75 L29.75,29.75 L29.75,26.25 L35,26.25 L35,29.75 Z M35,22.75 L29.75,22.75 L29.75,19.25 L35,19.25 L35,22.75 Z" />
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
           <span className="nav-link-text ms-1">Categorie</span>
          </Link>
        </li>
        
        
        
        
        
      </ul>
    </div>
   
  </div>
  <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
    {/* Navbar */}
  
  {/* Navbar */}
  <nav className="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl" id="navbarBlur" navbar-scroll="true">
      <div className="container-fluid py-1 px-3">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
            <li className="breadcrumb-item text-sm"><a className="opacity-5 text-dark" href="javascript:;">Accueil</a></li>
            <li className="breadcrumb-item text-sm text-dark active" aria-current="page">Commande</li>
          </ol>
          <h6 className="font-weight-bolder mb-0">Gestion Souscategorie : {nomcategorie}</h6>
        </nav>
        <div className="collapse justify-content-end navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">
         
          <ul className="navbar-nav  justify-content-end">
          <li className="nav-item d-flex align-items-center">
            <a className="btn btn-outline-primary btn-sm mb-0 me-3" data-bs-toggle="modal" data-bs-target={'#ajouterSouscategorie'}>+ Ajouter une Souscategorie</a>
            
       
  
            </li>
            <li className="nav-item d-flex align-items-center">
              <Link to="/login"href="javascript:;" className="nav-link text-body font-weight-bold px-0">
                <i className="fa fa-user me-sm-1" />
                <span className="d-sm-inline d-none">Deconnexion</span>
              </Link>
            </li>
            
                
              
          </ul>
        </div>
      </div>
    </nav>
    {/* End Navbar */}
    <div className="container-fluid py-4">
      <div className="row">
      <div className="modal fade"id={'ajouterSouscategorie'} tabindex="-1" role="dialog" aria-labelledby="modal-notification" aria-hidden="true">
      <div className="modal-dialog modal-danger modal-dialog-centered modal-" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title" id="modal-title-notification">Nouvelle Souscategorie</h6>
            <button type="button" className="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="py-3 text-center">
              
              <h4 className=" mt-4"><input type="text" value={souscategorie} 
              
              onChange={(e)=>{
                setSouscategorie(e.target.value)
              }}
              
              
              /></h4>
              <p></p>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" onClick={()=>{saveSouscategorie()}} data-bs-dismiss="modal" className="btn btn-white">Enregistrer</button>
            <button type="button" className="btn btn-link text-black ml-auto" data-bs-dismiss="modal">Annuler</button>
          </div>
        </div>
      </div>
    </div>
        
    <div class="ms-md-auto pe-md-6 d-flex align-items-center"><div class="input-group">
           <span class="input-group-text text-body"><i class="fas fa-search" aria-hidden="true"></i></span>
          </div></div>
     <table className="table align-items-center mb-0">
                  <thead>
                    <tr>{props.idcategorie}
                    </tr>
                    <tr>
                      <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">ID Souscategorie</th>
                      <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Souscategorie</th>
                      <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Sous Souscategorie</th>
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Supprimer</th>
                     
                      <th className="text-secondary opacity-7" />
                    </tr>
                  </thead>
                <tbody>
                {datacontentSouscategorie && datacontentSouscategorie.map((item,index) => (
                
                <tr key={item.idcategorie}> 
                  <td>
                  {item.idcategorie}
                  </td>
                  <td>
                    <p className="text-xs font-weight-bold mb-1">{item.nomcategorie}</p>
                  </td>
                  <Link  to="/souscategorie" state={{ idcategorie: item.idcategorie, nomcategorie: item.nomcategorie }} >
                  <td>
                    <p className="text-xs font-weight-bold mb-1">+ {item.souscategorie}</p>
                  </td>
                  </Link>
                  <td>
                  <a className="dropdown-item border-radius-md"  data-bs-toggle="modal" data-bs-target={'#supprimer' + item.idcategorie}> <p className="text-xs font-weight-bold mb-1"> Supprimer</p></a>

                  <a className="dropdown-item border-radius-md"  data-bs-toggle="modal" data-bs-target={'#modifier' + item.idcategorie}> <p className="text-xs font-weight-bold mb-1"> Modifier</p></a>
                      
                      <div class="modal fade"id={'supprimer' + item.idcategorie} tabindex="-1" role="dialog" aria-labelledby="modal-notification" aria-hidden="true">
      <div class="modal-dialog modal-danger modal-dialog-centered modal-" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="modal-title-notification">Attention</h6>
            <button type="button" class="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="py-3 text-center">
              <i class="ni ni-bell-55 ni-3x"></i>
              <h4 class="mt-4">Voullez vous supprimer cette categorie!</h4>
              <p>{item.nomcategorie}</p>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button"  data-bs-dismiss="modal" onClick={(e) => suppression(item.idcategorie)} class="btn btn-white">Oui</button>
            <button type="button" class="btn btn-link text-black ml-auto" data-bs-dismiss="modal">Annuler</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id={'modifier' + item.idcategorie}  role="dialog" aria-labelledby="modal-notification" aria-hidden="true">
      <div class="modal-dialog modal-danger modal-dialog-centered modal-" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="modal-title-notification">Modification</h6>
            <button type="button" class="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="py-3 text-center">
              <i class="ni ni-bell-55 ni-3x"></i>
              <h4 class="mt-4">{item.nomcategorie}</h4>
              <p> <input type="text" className="form-control"  onChange={(e) => functionmodifier(item.idcategorie,e.target.value)} placeholder="" aria-label="Email" aria-describedby="email-addon" />
              </p>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-link text-black ml-auto" data-bs-dismiss="modal">Fermer</button>
          </div>
        </div>
      </div>
    </div>
                      
                  </td>
                  </tr>

                  
))}
                 </tbody>
    </table>
        
      </div>
    </div>

    <footer className="footer pt-3  ">
        <div className="container-fluid">
          <div className="row align-items-center justify-content-lg-between">
            <div className="col-lg-6 mb-lg-0 mb-4">
              <div className="copyright text-center text-sm text-muted text-lg-start">
                © H2A GROUP.
              </div>
            </div>
        
          </div>
        </div>
      </footer>
    </main>
    </div>
    </div>
  )

            }


export default Souscategorie