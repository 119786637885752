import axios from 'axios';
import React from 'react'
import { useState, useEffect} from 'react'
import { Route, useNavigate, Link, redirect } from "react-router-dom";


function Tableproduit(props){
  var urllisteproduit ="";
const [reload,setreload] = useState(false);
const [Libelleoption,setLibelleoption] = useState("");
const [Prixoption,setPrixoption] = useState("");

  if(props.recherche==""){
  
    urllisteproduit = "https://jojomarket.net/foodshop/produit/produitslide.php";

  }else{
     urllisteproduit = "https://jojomarket.net/foodshop/produit/produitslide.php?recherche";

  }

  const [datacontentproduit, setdatacontentproduit] = useState([]);
useEffect(()=>{
  urllisteproduit = "https://jojomarket.net/foodshop/produit/produitslide.php";
  loadContent(urllisteproduit);
}, [reload]);

const modifstock = async(idproduit,stock)=>{
  var urlmodifstock = "https://jojomarket.net/adminfood/functionproduit.php?addstock&idproduit="+idproduit+"&stock="+stock;
  const responsemodif = await axios.get(urlmodifstock);
  document.getElementById(idproduit).innerText = stock;
  
}
const modifposition = async(idproduit,position)=>{
  var urlmodifpos = "https://jojomarket.net/adminfood/functionproduit.php?modifposition&idproduit="+idproduit+"&position="+position;
  const responsemodif = await axios.get(urlmodifpos);
 // document.getElementById(idproduit).innerText = position;
  
}
const modifrang = async(idproduit,rang)=>{
  var urlmodifrang = "https://jojomarket.net/adminfood/functionproduit.php?modifrang&idproduit="+idproduit+"&rang="+rang;
  const responsemodif = await axios.get(urlmodifrang);
//document.getElementById(idproduit).innerText = stock;
  
}



const modifprix = async(idproduit,prix)=>{
  var urlmodifprix = "https://jojomarket.net/adminfood/functionproduit.php?modifprix&idproduit="+idproduit+"&prix="+prix;
  const responsemodif = await axios.get(urlmodifprix);
  document.getElementById(idproduit).innerText = prix;
  
}

const suppression = async(idproduit)=>{
  var urlsup= "https://jojomarket.net/adminfood/functionproduit.php?supprimer&idproduit="+idproduit;
  const responsemodif = await axios.get(urlsup);
 // document.getElementById(idproduit).innerText = stock;
 //window.location.reload();
 //this.Tableproduit(props);
 setreload(true);
}
const ajoutoption = async(idproduit,libelle,prix)=>{
  var urlsup= "https://jojomarket.net/adminfood/functionproduit.php?ajoutoption&idproduit="+idproduit+"&libelle="+libelle+"&prix="+prix;
  const responseoption = await axios.get(urlsup);
 // document.getElementById(idproduit).innerText = stock;
 //window.location.reload();
 //this.Tableproduit(props);
 setreload(true);
}
  const[recherchedata,setRecherchedata] = useState([]);

// const handleChange(event){

// }
 

const loadContent = async(url)=>{
  const response = await axios.get(url);
  setdatacontentproduit(response.data);
}
  return (
    <>
         <div class="ms-md-auto pe-md-3 d-flex align-items-center"><div class="input-group">
           <span class="input-group-text text-body"><i class="fas fa-search" aria-hidden="true"></i></span>
           <input type="text" onChange={(e) => loadContent('https://jojomarket.net/foodshop/produit/produitslide.php?recherche='+e.target.value)} name='recherche' class="form-control" placeholder="Recherche ici..." kl_vkbd_parsed="true" /></div></div>
     <table className="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Article</th>
                      <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Prix </th>
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Fournisseur</th>
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Stock</th>
                      <th className="text-secondary opacity-7" />
                    </tr>
                  </thead>
                  <tbody>
                  {datacontentproduit && datacontentproduit.map((item,index) => (

                    <tr key={item.idproduit}> 
                      <td>
                        <div className="d-flex px-2 py-1">
                          <div>
                            <img src={"https://jojomarket.net/adminfood/images/"+item.idproduit+"-0.png"} className="avatar avatar-sm me-3" alt="user1" />
                          </div>
                          <div className="d-flex flex-column justify-content-center">
                            <h6 className="mb-0 text-sm"> {item.nomproduit}</h6>
                            <p className="text-xs text-secondary mb-0">{item.categorietext}</p>
                          </div>
                        </div>
                      </td>
                     
                      <td>
                        <p className="text-xs font-weight-bold mb-1">{item.prix}</p>
                      </td>
                   
                      <td>
                        <p className="text-xs font-weight-bold mb-1">{item.nomfournisseur}</p>
                      </td>
                      <td className="align-middle text-center text-sm" id={item.idproduit}>
                        {item.stock<10?
                        <span className="badge badge-sm bg-gradient-danger">{item.stock}</span>:
                        <span className="badge badge-sm bg-gradient-success">{item.stock}</span>
                      }
                      </td>
                      <td className="align-middle text-center">
                        <span className="text-secondary text-xs font-weight-bold">{item.nombrecommande}</span>
                      </td>
                      <td className="align-middle">
  <div>
  <span className=""><div className="dropdown float-lg-end pe-4"><a className="cursor-pointer btn" id="dropdownTable" data-bs-toggle="dropdown" aria-expanded="false"><i className="fa fa-bars text-secondary" aria-hidden="true" /></a>
          <ul className="dropdown-menu px-2 py-3 ms-sm-n4 ms-n5" aria-labelledby="dropdownTable" style={{}}>
            <li><a className="dropdown-item border-radius-md" href={'https://jojomarket.net/article.php?idarticle='+item.idproduit}>Voir tous</a></li>
           
            <li>  <Link  to="/modifproduit" state={{  idproduit0:item.idproduit,nomproduit0:item.nomproduit,prix0:item.prix, categorie0:item.categorie, categorietext0:item.categorietext,fournisseur0:item.nomfournisseur, description0:item.description, stock0:item.stock }} >
                 
            <div className="dropdown-item border-radius-md"> Modifier </div>
                  
                  </Link>
                  
                  </li>
                  <li><a className="dropdown-item border-radius-md"  data-bs-toggle="modal" data-bs-target={'#modifrang' + item.idproduit}>Rang</a></li>
                  <li><a className="dropdown-item border-radius-md"  data-bs-toggle="modal" data-bs-target={'#option' + item.idproduit}>Options</a></li>
            <li><a className="dropdown-item border-radius-md"  data-bs-toggle="modal" data-bs-target={'#supprimer' + item.idproduit}>Supprimer</a></li>
        
           </ul></div></span>
           
    <div class="modal fade"id={'option' + item.idproduit} tabindex="-1" role="dialog" aria-labelledby="modal-notification" aria-hidden="true">
      <div class="modal-dialog modal-danger modal-dialog-centered modal-" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="modal-title-notification">Options</h6>
            <button type="button" class="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="py-3 text-center">
            {item.options && item.options.map((option,index) => (
            <h5>- {option.libelle} : {option.prix} fcfa</h5>
            ))}
              <form role="form text-left">
                <label> Ajouter une options </label>
                <div className="input-group mb-3">
                  <input type="text" className="form-control"  onChange={(e) =>{setLibelleoption(e.target.value)}} placeholder="Libellé"  aria-describedby="email-addon" />
                </div>
                <div className="input-group mb-3">
                  <input type="number" className="form-control"  onChange={(e) =>{setPrixoption(e.target.value)}} placeholder="Prix"  aria-describedby="email-addon" />
                </div>
              
                
                <div className="text-center">
               <button type="button"  data-bs-dismiss="modal"  className="btn btn-round bg-gradient-info btn-lg w-100 mt-4 mb-0" onClick={()=>{ajoutoption(item.idproduit,Libelleoption,Prixoption)}}>Envoyer</button>
                </div>
              </form>

            
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" onClick={(e) => suppression(item.idproduit)} data-bs-dismiss="modal" class="btn btn-white">Oui</button>
            <button type="button" class="btn btn-link text-black ml-auto" data-bs-dismiss="modal">Annuler</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade"id={'supprimer' + item.idproduit} tabindex="-1" role="dialog" aria-labelledby="modal-notification" aria-hidden="true">
      <div class="modal-dialog modal-danger modal-dialog-centered modal-" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="modal-title-notification">Attention</h6>
            <button type="button" class="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="py-3 text-center">
              <i class="ni ni-bell-55 ni-3x"></i>
              <h4 class="text-gradient text-danger mt-4">Voullez vous supprimer cet article!</h4>
              <p>{item.nomproduit}</p>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" onClick={(e) => suppression(item.idproduit)} data-bs-dismiss="modal" class="btn btn-white">Oui</button>
            <button type="button" class="btn btn-link text-black ml-auto" data-bs-dismiss="modal">Annuler</button>
          </div>
        </div>
      </div>
    </div>




  <div className="modal fade" id={'modifrang' + item.idproduit} tabIndex={-1} role="dialog" aria-labelledby="modal-form" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
      <div className="modal-content">
        <div className="modal-body p-0">
          <div className="card card-plain">
            <div className="card-header pb-0 text-left">
              <h3 className="font-weight-bolder text-info text-gradient">Modifier le rang</h3>
              <p className="mb-0">{item.nomproduit}</p>
            </div>
            <div className="card-body">
              <form role="form text-left">
                <label>Nouveau rang</label>
                <div className="input-group mb-3">
                  <input type="number" className="form-control"  onChange={(e) => modifrang(item.idproduit,e.target.value)} placeholder="" aria-label="Email" aria-describedby="email-addon" />
                </div>
              
                
                <div className="text-center">
               <button type="button"  data-bs-dismiss="modal"  className="btn btn-round bg-gradient-info btn-lg w-100 mt-4 mb-0">Envoyer</button>
                </div>
              </form>
            </div>
          
          </div>
        </div>
      </div>
    </div>
  </div>

  <div className="modal fade" id={'modifierprix' + item.idproduit} tabIndex={-1} role="dialog" aria-labelledby="modal-form" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
      <div className="modal-content">
        <div className="modal-body p-0">
          <div className="card card-plain">
            <div className="card-header pb-0 text-left">
              <h3 className="font-weight-bolder text-info text-gradient">Modifier Prix</h3>
              <p className="mb-0">{item.nomproduit}</p>
            </div>
            <div className="card-body">
              <form role="form text-left">
                <label>Nouveau Prix</label>
                <div className="input-group mb-3">
                  <input type="number" className="form-control"  onChange={(e) => modifprix(item.idproduit,e.target.value)} placeholder="" aria-label="Email" aria-describedby="email-addon" />
                </div>
              
                
                <div className="text-center">
               <button type="button"  data-bs-dismiss="modal"  className="btn btn-round bg-gradient-info btn-lg w-100 mt-4 mb-0">Envoyer</button>
                </div>
              </form>
            </div>
          
          </div>
        </div>
      </div>
    </div>
  </div>









  <div className="modal fade" id={'modifier' + item.idproduit} tabIndex={-1} role="dialog" aria-labelledby="modal-form" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
      <div className="modal-content">
        <div className="modal-body p-0">
          <div className="card card-plain">
            <div className="card-header pb-0 text-left">
              <h3 className="font-weight-bolder text-info text-gradient">Modifier Stock</h3>
              <p className="mb-0">{item.nomproduit}</p>
            </div>
            <div className="card-body">
              <form role="form text-left">
                <label>Nouveau Stock</label>
                <div className="input-group mb-3">
                  <input type="number" className="form-control"  onChange={(e) => modifstock(item.idproduit,e.target.value)} placeholder="" aria-label="Email" aria-describedby="email-addon" />
                </div>
              
                
                <div className="text-center">
                 <button data-bs-dismiss="modal"  type="button"  className="btn btn-round bg-gradient-info btn-lg w-100 mt-4 mb-0">Envoyer</button>
                </div>
              </form>
            </div>
          
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="modal fade" id={'modifierrang' + item.idproduit} tabIndex={-1} role="dialog" aria-labelledby="modal-form" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
      <div className="modal-content">
        <div className="modal-body p-0">
          <div className="card card-plain">
            <div className="card-header pb-0 text-left">
              <h3 className="font-weight-bolder text-info text-gradient">Modifier Stock</h3>
              <p className="mb-0">{item.nomproduit}</p>
            </div>
            <div className="card-body">
              <form role="form text-left">
                <label>Nouveau Stock</label>
                <div className="input-group mb-3">
                  <input type="number" className="form-control"  onChange={(e) => modifrang(item.idproduit,e.target.value)} placeholder="" aria-label="Email" aria-describedby="email-addon" />
                </div>
              
                
                <div className="text-center">
                 <button data-bs-dismiss="modal"  type="button"  className="btn btn-round bg-gradient-info btn-lg w-100 mt-4 mb-0">Envoyer</button>
                </div>
              </form>
            </div>
          
          </div>
        </div>
      </div>
    </div>
  </div>


</div>

                      </td>
                    </tr>
        ))}
                  </tbody>
                </table>
   
   
 </>
  )
}
export default Tableproduit;