import React, {useState, useEffect} from 'react';

import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const api ="https://jojomarket.net/adminfood/loginadmin.php"; 

const initialState = {

  email:"",
  password:""
}

  // if (toDashboard === true) {
  //   return <Navigate to="/home" />;
  // }

  // Somewhere in your code, e.g. inside a handler:
 


 function Login(){




  
  const [formValue, setformValue] = React.useState({
 
  email:"",
  password:""
  });

  const navigate = useNavigate();

  const handleChange = (event) => {
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value
    });
  }

  function TokenStorage(Token){
    if(Token!=='erreur'){
    //const history = useHistory();
    sessionStorage.setItem("Token", Token);
    //toast.success('Bienvenue');
    
    
    navigate('/');

    };
    if(Token=='erreur'){
       alert('errreur de Login');
        // toast.error('Login Incorrect!', {
        //     position: "top-center",
        //     autoClose: 5000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //     theme: "light",
        //     });
    }
    //<Navigate to="/home" />;
    //navigate("/home");
  }



const handleSubmit = async(e) => {
    // store the states in the form data
    e.preventDefault();
    const loginFormData = new FormData();
    loginFormData.append("email", formValue.email);
    loginFormData.append("password", formValue.password);
  
    try {
      // make axios post request
      const response = await axios({
        method: "post",
        url: api,
        data: loginFormData,
        headers: { "Content-Type": "multipart/form-data" },
      }).then(response => TokenStorage(response.data));
    } catch(error) {
      console.log(error)
    }
  }

  return (
   <main className="main-content  mt-0">
  <section>
    <div className="page-header min-vh-75">
      <div className="container">
        <div className="row">
          <div className="col-xl-4 col-lg-5 col-md-6 d-flex flex-column mx-auto">
            <div className="card card-plain mt-8">
              <div className="card-header pb-0 text-left bg-transparent">
                <h3 className="font-weight-bolder text-info text-gradient">Administration</h3>
                <p className="mb-0">Barka Da Zoua</p>
              </div>
              <div className="card-body">
                <form role="form" onSubmit={handleSubmit}>
                  <label>Email</label>
                  <div className="mb-3">
                    <input value={formValue.email} onChange={handleChange} name="email"type="email" className="form-control" placeholder="Email" aria-label="Email" aria-describedby="email-addon" />
                  </div>
                  <label>Mots de passe</label>
                  <div className="mb-3">
                    <input type="password"  value={formValue.password}  onChange={handleChange} name="password" className="form-control" placeholder="Mots de passe" aria-label="Password" aria-describedby="password-addon" />
                  </div>
                  <div className="text-center">
                    <button type="submit" className="btn bg-gradient-info w-100 mt-4 mb-0">Connexion</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <img src="../assets/img/logo.jpg"/>
            
          </div>
        </div>
      </div>
    </div>
  </section>
</main>

  )
}
export default Login;