import React from 'react'
import axios from 'axios';
import { useState, useEffect} from 'react'
import { Link, useNavigate } from 'react-router-dom';




export const Tablecommande = () => {
  const openInNewTab = url => {
    // 👇️ setting target to _blank with window.open
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  const navigate = useNavigate();
var urlgetlivreur = "https://jojomarket.net/adminfood/functionlivreur.php?getlivreur";
  var urllistecmd ="https://jojomarket.net/adminfood/functioncommande.php?listecommande";
  var urllivreur ="https://jojomarket.net/adminfood/functioncommande.php?listelivreur";
  var urlattribuercmd ="https://jojomarket.net/adminfood/functioncommande.php?attribuer";
  var urlsheck="https://jojomarket.net/adminfood/functioncommande.php?checkcommande";

  
  const [datacontent, setDatacontent] = useState([]);
  const[listelivreur, seltlistelivreur] = useState([]);
  const[sheckcommande, setSheckcommande] = useState([]);
  const[filtre, setfiltre] = useState(0);
  const[attriblivreur, setAttriblivreur] = useState(0);
  const[montantencaisse, setmontantencaisse] = useState(0);
  useEffect(()=>{
    var urllistecmd ="https://jojomarket.net/adminfood/functioncommande.php?listecommande";
    var urllivreur ="https://jojomarket.net/adminfood/functioncommande.php?listelivreur";
    var urlsheck="https://jojomarket.net/adminfood/functioncommande.php?checkcommande";
    loadContent(urllistecmd, urllivreur);
    loadSheck(urlsheck);
  }, []);
const interval=setInterval(()=>{
  loadSheck(urlsheck);
  },20000)


const modifstock = async(idproduit,stock)=>{
  var urlmodifstock = "https://jojomarket.net/adminfood/functionproduit.php?addstock&idproduit="+idproduit+"&stock="+stock;
  const responsemodif = await axios.get(urlmodifstock);
  document.getElementById(idproduit).innerText = stock;
}
const suppression = async(idproduit)=>{
  var urlsup= "https://jojomarket.net/adminfood/functionproduit.php?supprimer&idproduit="+idproduit;
  const responsemodif = await axios.get(urlsup);
 // document.getElementById(idproduit).innerText = stock;
// window.location.reload();
 //this.Tableproduit(props);
}
// const handleChange(event){

// }
 
const loadSheck = async(urlsheck)=>{
  const responsesheck = await axios.get(urlsheck);
  setSheckcommande(responsesheck.data);

}

const loadContent = async(url, urllivreur)=>{
  const response = await axios.get(url);
  setDatacontent(response.data);
  const responselivreur = await axios.get(urllivreur);
  seltlistelivreur(responselivreur.data);
  
  
}



const attribuerreapide = async(idcommande,idlivreur)=>{
  var urlmodifstock = "https://jojomarket.net/adminfood/functioncommande.php?attribuer&idcommande="+idcommande+"&idlivreur="+idlivreur;
  const responsemodif = await axios.get(urlmodifstock);
  //document.getElementById(idproduit).innerText = stock;
 loadContent(urllistecmd, urllivreur);
}


const functionencaisse = async(idcommande,montant)=>{
  var urlencaisse= "https://jojomarket.net/adminfood/functioncommande.php?encaisse&idcommande="+idcommande+"&montant="+montant;
  const responsemodif = await axios.get(urlencaisse);
  //document.getElementById(i).innerText = stock;
  loadContent(urllistecmd, urllivreur);
}

  return (
    <div> 
    <div className="row my-4">
      <div className="col-lg-812col-md-12 mb-md-0 mb-4">
        <div className="card">
          <div className="card-header pb-0">
            <div className="row">
              <div className="col-lg-6 col-7">
                <h6>Gestion des commandes</h6>
                
              </div>
              <div className="col-lg-6 col-5 my-auto text-end">
              <p className="text-sm mb-0 ">
              {sheckcommande ? (<>
              <div  onClick={() => loadContent(urllistecmd, urllivreur)}>
                  <img src='https://i.pinimg.com/originals/fb/11/55/fb1155591460c455edf3ced130b127b9.gif'  className='max-height-vh-10' />
                  <span className="font-weight-bold ms-2">{sheckcommande} Nouvelle(s) commande(s) <br/>
                  <i onClick={()=>{setfiltre(0)}} className="fa fa-refresh" />&nbsp;&nbsp;&nbsp;
                  <i  onClick={()=>{setfiltre(1)}} className="fa fa-paper-plane" />&nbsp;&nbsp;&nbsp;
                  <i  onClick={()=>{setfiltre(2)}} className="fa fa-check-square-o" />&nbsp;&nbsp;&nbsp;
                 
               </span>
               </div>
                  </>
              ):<></>
            
            }
                </p>
              </div>
            </div>
          </div>
          <div className="card-body px-0 pb-2">
            <div className="table-responsive">
              <table className="table align-items-center mb-0 ">
                <thead>
                  <tr>

                    <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Action</th>
                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Idcommande</th>
                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Produit</th>
                    <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Prix(FCFA)</th>
                    <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Client</th>
                    <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Telephone</th>
                    <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Adresse</th>
                    <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Localisation</th>
                    <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Date</th>
                    <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</th>
                  </tr>
                </thead>
                <tbody>



                {datacontent && datacontent.map((item,index) => (
               <>
               {item.statu==filtre?
               <tr key={item.idcommande}>
                 <td className="align-middle">
                      
                      <span className="text-xs font-weight-bold"><span className="badge"><div className="dropdown float-lg-end pe-4"><a className="cursor-pointer" id="dropdownTable" data-bs-toggle="dropdown" aria-expanded="false"><i className="fa fa-bars text-secondary" aria-hidden="true" /></a>
  <ul className="dropdown-menu px-2 py-3 ms-sm-n4 ms-n5" aria-labelledby="dropdownTable" style={{}}>
  {item.statu==0?<li><a className="dropdown-item border-radius-md" data-bs-toggle="modal" data-bs-target={'#attribuer' + item.idcommande}>Attribuer</a></li>:item.statu==1?<li><a className="dropdown-item border-radius-md" data-bs-toggle="modal" data-bs-target={'#encaisse' + item.idcommande}>Encaissé</a></li>:<span class="badge badge-sm bg-gradient-success">Encaissé</span>}
              
   
    </ul>
    <div className="modal fade" id={'encaisse' + item.idcommande} tabIndex={-1} role="dialog" aria-labelledby="modal-form" aria-hidden="true">
<div className="modal-dialog modal-dialog-centered modal-lg" role="document">
<div className="modal-content">
  <div className="modal-body p-0">
    <div className="card card-plain">
      <div className="card-header pb-0 text-left">
        <h3 className="font-weight-bolder text-info text-gradient">Encaissser</h3>
        <span className="text-xs font-weight-bold">
          {item.listeproduit.map((skillDetail) => {
                         return(
                           <div style={{color:'black'}}>
                              {skillDetail.nomproduit} - {skillDetail.quantite} 
                              <br/>
                           </div>
                         )
                        ;
                      })
                    }</span>
                    <h5>valeur : {item.prix}</h5>
      </div>
      <div className="card-body">
        <form role="form text-left">
          <label></label>
          <div className="input-group mb-12 col-md-12">
            <input type="number" className="form-control  mb-12 col-md-12"  onChange={(e) => setmontantencaisse(e.target.value)} placeholder="" aria-label="Email" aria-describedby="email-addon" />
       
            <div className="form-control btn  mb-1 col-md-12"  onClick={(e) => functionencaisse(item.idcommande,montantencaisse)}  data-bs-dismiss="modal"> Valider </div>
       
          </div>
        
          
         
        </form>
      </div>
    
    </div>
  </div>
</div>
</div>
</div>
    <div class="modal fade"id={'attribuer' + item.idcommande} tabindex="-1" role="dialog" aria-labelledby="modal-notification" aria-hidden="true">
<div class="modal-dialog modal-danger modal-dialog-centered modal-" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <h6 class="modal-title" id="modal-title-notification">Attribuer A Un Livreur</h6>
      <button type="button" class="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="py-3 text-center">
      <div class="card-body">
          <form role="form text-left" >
          <div class="form-group">
  <input type="text" placeholder="Regular" name='idcommande' value={item.idcommande} class="form-control" disabled />
</div>
            <div class="form-group">

<select class="form-control" id="idlivreur" value={item.idlivreur}  onChange={(e) =>setAttriblivreur(e.target.value)} name="idlivreur">
<option key='defaut'>Choisir un livreur</option>
{listelivreur && listelivreur.map((itemlive,index) => (
<option   key={itemlive.idlivreur} value={itemlive.idlivreur} data-bs-dismiss="modal">{itemlive.nomlivreur}</option>
))}


</select>
<div className="form-control btn  col-md-12"  onClick={(e) =>attribuerreapide(item.idcommande,attriblivreur)}  data-bs-dismiss="modal"> Valider </div>
</div>
<div class="modal-footer">
      <button type="button" class="btn btn-link text-black ml-auto" data-bs-dismiss="modal">Fermer</button>
    </div>
            
          </form>
        </div>
     




      </div>
    </div>
   
  </div>
</div>
</div></div></span></span>
                   
               
              </td>
                    <td className="align-middle text-center text-sm">
                    <span className="text-xs font-weight-bold">{item.idcommande}</span> 
                    </td>
                    <td>
                      <div className="avatar-group mt-2" style={{height:100,overflowY: "scroll",msOverflowY:"visible"}}>
                      <span className="text-xs font-weight-bold">  
                       {
                         
                      item.listeproduit.map((skillDetail) => {
                               return(
                                 <span>
                                    {skillDetail.nomproduit} - {skillDetail.quantite}
                                    <br/>
                                 </span>
                               )
                              ;
                            })
                          }
                          </span>
                      </div>
                    </td>
                    <td className="align-middle text-center text-sm">
                      <span className="text-xs font-weight-bold">  {item.prix}</span>
                    </td>
                    <td className="align-middle text-center text-sm">
                      <span className="text-xs font-weight-bold">   {
                         
                         item.clientcmd.map((clientDetail) => {
                                  return(
                                    <>
                                       {clientDetail.nomclient} 
                                      
                                    </>
                                  )
                                 
                               })
                             }</span>
                    </td>
                    <td className="align-middle text-center text-sm">
                      <span className="text-xs font-weight-bold">   {
                         
                         item.clientcmd.map((clientDetail2) => {
                                  return(
                                    <>
                                       {clientDetail2.telephoneclient} 
                                      
                                    </>
                                  )
                                 
                               })
                             }</span>
                    </td>
                    <td className="align-middle text-center text-sm">
                      <span className="text-xs font-weight-bold">   
                         
                       
                                    <>
                                       {item.adresse} 
                                      
                                    </>
                              </span>
                    </td>
                    <td className="align-middle text-center text-sm">
                      <span className="text-xs font-weight-bold">   
                        
                         <button className='badge badge-sm bg-gradient-secondary' onClick={() => openInNewTab("https://maps.google.com/?q="+item.latitude+","+item.longitude)} >Voir Localisation</button>
                        </span>
                    </td>
                    <td className="align-middle">
                      
                            <span className="text-xs font-weight-bold">{item.datecommande}</span>
                         
                     
                    </td>
                   
                    <td className="align-middle">
                      
                            <span className="text-xs font-weight-bold">{item.statu==0?<span class="badge badge-sm bg-gradient-danger red">Non Validé</span>:item.statu==1?<span class="badge badge-sm bg-gradient-secondary">En cours</span>:<span class="badge badge-sm bg-gradient-success">Livré</span>}</span>
                         
                     
                    </td>
                   
                  </tr>
                 
:<></>} </>
                ))}



                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="col-lg-4 col-md-6">
        <div className="card h-100">
          <div className="card-header pb-0">
            <h6>Livreurs</h6>
         
          </div>
          <div className="card-body p-0">
          <div class="card mb-4">
         
         <div className="card-body px-0 pt-0 pb-0">
<div className="table-responsive p-0">
  <table className="table align-items-center mb-0">
    <thead>
      <tr>
        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Nom</th>
        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Status</th>
        <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Telephone</th>
       
      </tr>
    </thead>
    <tbody>{listelivreur && listelivreur.map((itemlive,index) => (


<tr key={itemlive.idlivreur} >
<td>
  <div className="d-flex px-2 py-1">
    
    <div className="d-flex flex-column justify-content-center">
      <h6 className="mb-0 text-sm">{itemlive.nomlivreur}</h6>
      <p className="text-xs text-secondary mb-0"> {itemlive.secteur}</p>
    </div>
  </div>
</td>
<td className="align-middle text-center text-sm">
{itemlive.nombrecommande>0?<span className="badge badge-sm bg-gradient-secondary">Indisponible ({itemlive.nombrecommande})</span>:<span className="badge badge-sm bg-gradient-success">Disponible</span>}
  
</td>

<td className="align-middle text-center text-sm">
  {itemlive.telephone}
</td>
</tr>

))}
     
    
    </tbody>
  </table>
</div>
</div>






        </div>
          </div>
        </div>
      </div> */}
    </div></div>
  )
}
export default Tablecommande