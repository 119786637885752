import { useState } from 'react';
import { Route, Routes} from "react-router-dom";
import {
    useRoutes
  } from 'react-router-dom';
import './App.css';
import Home from './pages/home';
import Produit from './pages/produit';
import Ajoutproduit from './pages/ajoutproduit'
import Login from './pages/login';
import Categorie from './pages/categorie';
import Souscategorie from './pages/Souscategorie';
import Modifproduit from './pages/modifproduit';
function App() {
  let element = useRoutes([
    {path: '/', element: <Home />},
    {path: '/home', element: <Home />},
    {path: '/produit', element: <Produit />},
    {path: '/ajoutproduit', element: <Ajoutproduit />},
    {path: '/login', element: <Login />},
    {path: '/categorie', element: <Categorie />},
    {path: '/souscategorie', element: <Souscategorie />},
    {path: '/modifproduit', element: <Modifproduit />},
    ]);

  return element;
}

export default App;
